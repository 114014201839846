import * as React from "react"

import { Link } from "gatsby"
import Layout from "../../components/layout"
import Seo from "../../components/seo"
// import PageHero from "../../components/template-partials/page-hero"
import FooterCrown from "../../components/template-partials/footer-crown"
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome"

import logo1 from "../../images/logos/logo1.png"
import logo2 from "../../images/logos/logo2.png"
import logo3 from "../../images/logos/logo3.png"
import logo4 from "../../images/logos/logo4.png"
import logo5 from "../../images/logos/logo5.png"
import logo6 from "../../images/logos/logo6.png"
import logo7 from "../../images/logos/logo7.webp"
import QuestionsSM from "../../components/template-partials/questions-sm"
// import linkOutIcon from "../../images/link_out_bold.svg"
import hrImage from "../../images/hr-image.svg"

import link_out from "../../images/link_out_purple.svg"

import {
  faAngleRight,
  // faPlus,
  // faMinus,
} from "@fortawesome/free-solid-svg-icons"

const QuestionsSMBlockButtons = [
  { text: "VIEW FAQs", to: "/sm-resources/faqs/", arrow: true },
]

const DownloadsPage = () => (
  <Layout route="sm-resources/advocacy">
    <Seo
      page="test-kit"
      title="SM Patient Advocacy"
      description="A list of advocacy groups that can be used independently as a resource for patients living with
systemic mastocytosis (SM) to help stay informed and strengthen their support network."
    />
    <div className="row lg_mt-3 mt-1 align-center medium-align-left">
      <div className="columns small-12 large-12">
        <h1 className="color--purple text--watch-quinn">
          Advocacy resources for your patients with systemic
          <br className="show-for-large" /> mastocytosis (SM)
        </h1>
        <p className="mt-3 mb-3 bodyP purple">
          When patients and caregivers connect with SM communities and
          organizations, they can stay informed and strengthen their support
          network. These independent advocacy groups can help. They are included
          here as a reference for patients and do not imply endorsement of
          Blueprint Medicines or its products by the groups listed.
        </p>
        <p className="bodyP purple">
          Blueprint Medicines is not affiliated with and does not endorse any
          particular advocacy group. We make no guarantees about the accuracy of
          the information provided on advocacy groups’ websites or the quality
          of support provided.
        </p>
      </div>
    </div>

    <div className="row lg_mt-2 mt-7 lg_mb-2 mb-2 align-center medium-align-left mobile-padding">
      <div className="advocacy_div centered_div ">
        <div className="advocacy_inner ">
          <img
            alt="Allergy & Asthma Network logo"
            src={logo1}
            className="logoImg"
            width="100%"
            height="auto"
          />

          <a
            href="https://allergyasthmanetwork.org/"
            target="_blank"
            rel="noreferrer"
          >
            <span
              className="underline"
              target="_blank"
              href="https://allergyasthmanetwork.org/"
            >
              Allergy & Asthma Network
            </span>{" "}
            <img
              alt="Link out icon"
              src={link_out}
              width="100%"
              height="auto"
              target="_blank"
              href="https://allergyasthmanetwork.org/"
            />
          </a>
        </div>

        <div className="advocacy_inner ">
          <img
            alt="Asthma and Allergy Foundation of America logo"
            src={logo2}
            className="logoImg"
            width="100%"
            height="auto"
          />

          <a href="https://www.aafa.org/" target="_blank" rel="noreferrer">
            <span
              className="underline"
              target="_blank"
              href="https://www.aafa.org/"
            >
              Asthma and Allergy Foundation of America
            </span>{" "}
            <img
              alt="Link out icon"
              src={link_out}
              width="100%"
              height="auto"
              target="_blank"
              href="https://www.aafa.org/"
            />
          </a>
        </div>

        <div className="advocacy_inner ">
          <img
            alt="Cancer Support Community and Gilda's Club logo"
            src={logo3}
            className="logoImg"
            width="100%"
            height="auto"
          />

          <a
            href="https://www.cancersupportcommunity.org/"
            target="_blank"
            rel="noreferrer"
          >
            <span
              className="underline"
              target="_blank"
              href="https://www.cancersupportcommunity.org/"
            >
              Cancer Support Community
            </span>{" "}
            <img
              alt="Link out icon"
              src={link_out}
              width="100%"
              height="auto"
              target="_blank"
              href="https://www.cancersupportcommunity.org/"
            />
          </a>
        </div>

        <div className="advocacy_inner ">
          <img
            alt="Global Genes logo"
            src={logo4}
            className="logoImg"
            width="100%"
            height="auto"
          />

          <a href="https://globalgenes.org/" target="_blank" rel="noreferrer">
            <span
              className="underline"
              target="_blank"
              href="https://globalgenes.org/"
            >
              Global Genes
            </span>{" "}
            <img
              alt="Link out icon"
              src={link_out}
              width="100%"
              height="auto"
              target="_blank"
              href="https://globalgenes.org/"
            />
          </a>
        </div>

        <div className="advocacy_inner ">
          <img
            alt="The Mast Cell Disease Society logo"
            src={logo6}
            className="logoImg"
            width="100%"
            height="auto"
          />

          <a href="https://tmsforacure.org/" target="_blank" rel="noreferrer">
            <span
              className="underline"
              target="_blank"
              href="https://tmsforacure.org/"
            >
              The Mast Cell Disease Society
            </span>{" "}
            <img
              alt="Link out icon"
              src={link_out}
              width="100%"
              height="auto"
              target="_blank"
              href="https://tmsforacure.org/"
            />
          </a>
        </div>

        <div className="advocacy_inner ">
          <img
            alt="National Organization for Rare Disorders logo"
            src={logo7}
            className="logoImg"
            width="100%"
            height="auto"
          />

          <a href="https://rarediseases.org/" target="_blank" rel="noreferrer">
            <span
              className="underline"
              target="_blank"
              href="https://rarediseases.org/"
            >
              NORD
            </span>{" "}
            <img
              alt="Link out icon"
              src={link_out}
              width="100%"
              height="auto"
              target="_blank"
              href="https://rarediseases.org/"
            />
          </a>
        </div>
      </div>
    </div>

    <QuestionsSM
      hrImage={hrImage}
      text="Have questions about SM?"
      QuestionsSMBlockButtons={QuestionsSMBlockButtons}
    />

    <div className="row lg_mt-7 mt-7 lg_mb-7 mb-7 align-center medium-align-left">
      <div className="columns small-12 medium-expand text-right">
        <p className="h4 color--purple">Stay updated on SM information</p>
        <Link
          className="btn btn--outline btn--icon uppercase mt-1"
          to="/sign-up/"
        >
          SIGN UP <FontAwesomeIcon className="" icon={faAngleRight} />
        </Link>
      </div>
    </div>

    <FooterCrown type="home" />
  </Layout>
)

export default DownloadsPage
